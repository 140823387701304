<template>
  <b-card>
    <!-- form -->
    <validation-observer ref="general">
      <b-form ref="general_form" class="mt-2">
        <b-row v-if="!isLoading">
          <b-col sm="6">
            <b-form-group
              :label="$t('form.page.profile.Fullname')"
              label-for="account-fullname"
            >
              <validation-provider
                #default="{ errors }"
                name="Fullname"
                rules="required"
              >
                <b-form-input
                  disabled
                  v-model="paramUser.fullName"
                  :placeholder="$t('form.page.profile.Fullname')"
                  name="fullname"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group
              :label="$t('form.page.profile.Email')"
              label-for="account-email"
            >
              <validation-provider
                #default="{ errors }"
                name="Email"
                rules="required|email"
              >
                <b-form-input
                  disabled
                  v-model="paramAddress.email.value"
                  :placeholder="$t('form.page.profile.Email')"
                  name="email"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col sm="6" v-if="isIndividual">
            <b-form-group
              :label="$t('form.page.profile.Birth Date')"
              label-for="account-birth"
            >
              <validation-provider
                #default="{ errors }"
                name="Birth"
                rules="required"
              >
                <flat-pickr
                  disabled
                  readonly
                  v-model="paramUser.birth"
                  class="form-control"
                  name="date"
                  :placeholder="$t('form.page.profile.Birth Date')"
                />
                <!--b-form-input v-model="birth" placeholder="Birth" name="birth" :state="errors.length > 0 ? false : null"/-->
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col sm="6" v-if="isIndividual">
            <b-form-group
              :label="$t('form.page.profile.Birth Place')"
              label-for="account-birthplace"
            >
              <validation-provider
                #default="{ errors }"
                name="Birthplace"
                rules="required"
              >
                <b-form-input
                  disabled
                  v-model="paramUser.birthPlace"
                  :placeholder="$t('form.page.profile.Birth Place')"
                  name="birthplace"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group
              :label="$t('form.page.profile.Address')"
              label-for="bio-address"
            >
              <validation-provider
                #default="{ errors }"
                name="Address"
                rules="required"
              >
                <b-form-textarea
                  disabled
                  id="bio-address"
                  v-model="paramAddress.street_address.value"
                  rows="4"
                  :placeholder="$t('form.page.profile.Address')"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group
              :label="$t('form.page.profile.Tax Address')"
              label-for="bio-taxaddress"
            >
              <validation-provider
                #default="{ errors }"
                name="Tax Address"
                rules="required"
              >
                <b-form-textarea
                  disabled
                  id="bio-taxaddress"
                  v-model="paramAddress.tax_address.value"
                  rows="4"
                  :placeholder="$t('form.page.profile.Tax Address')"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group
              :label="$t('form.page.profile.invoice shipping')"
              label-for="bio-taxaddress"
            >
              <validation-provider
                #default="{ errors }"
                name="Invoice Shipping Address"
                rules="required"
              >
                <b-form-textarea
                  disabled
                  id="bio-taxaddress"
                  v-model="paramAddress.invoice_shipping.value"
                  rows="4"
                  :placeholder="$t('form.page.profile.invoice shipping')"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group
              :label="$t('form.page.profile.Phone Number')"
              label-for="account-phone"
            >
              <validation-provider
                #default="{ errors }"
                name="Phone"
                rules="required"
              >
                <b-form-input
                  disabled
                  v-model="paramAddress.phone_number.value"
                  :placeholder="$t('form.page.profile.Phone Number')"
                  name="phone"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- alert -->
          <!--b-col cols="12" class="mt-75">
            <b-alert show variant="warning" class="mb-50">
              <h4 class="alert-heading">
                Your email is not confirmed. Please check your inbox.
              </h4>
              <div class="alert-body">
                <b-link class="alert-link">
                  Resend confirmation
                </b-link>
              </div>
            </b-alert>
          </b-col-->
          <!--/ alert -->

          <!--<b-col cols="12" v-if="!isLoading">

            HARAP CEK LAGI API NYA POINTING KE DB YG BARU. KALAU FITUR INI DI AKTIFIN

            <b-button disabled v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mt-2 mr-1" @click.prevent="validationForm">Save changes</b-button>
            <b-button disabled v-ripple.400="'rgba(186, 191, 199, 0.15)'" variant="outline-secondary" type="reset" class="mt-2" @click.prevent="resetForm">Reset</b-button>
          </b-col>-->
        </b-row>
        <template v-if="isLoading">
          <loading></loading>
        </template>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import {
  BFormFile,
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BAlert,
  BCard,
  BCardText,
  BMedia,
  BMediaAside,
  BMediaBody,
  BLink,
  BImg,
  BFormTextarea,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { useInputImageRenderer } from "@core/comp-functions/forms/form-utils";
import { ref } from "@vue/composition-api";
import axios from "@axios";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { required, email } from "@validations";
import Loading from "@core/components/loading/Loading.vue";
import flatPickr from "vue-flatpickr-component";

export default {
  components: {
    BButton,
    BForm,
    BImg,
    BFormFile,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BAlert,
    BCard,
    BCardText,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
    BFormTextarea,
    ValidationProvider,
    ValidationObserver,
    Loading,
    flatPickr,
  },
  directives: {
    Ripple,
  },
  props: {
    generalData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      optionsLocal: JSON.parse(JSON.stringify(this.generalData)),
      paramUser: {
        fullName: null,
        birth: null,
        birthPlace: null,
      },
      paramLog: {
        previous_data: null,
        new_data: null,
      },
      paramAddress: {
        email: {
          value: null,
          n_addr_type_id: 6006,
          n_subj_address_id: null,
          n_subj_addr_type_id: 1016,
          n_region_id: null,
        },
        phone_number: {
          value: null,
          n_addr_type_id: 13006,
          n_subj_address_id: null,
          n_subj_addr_type_id: 10016,
          n_region_id: null,
        },
        street_address: {
          value: null,
          n_addr_type_id: 1006,
          n_subj_address_id: null,
          n_subj_addr_type_id: 1016,
          n_region_id: null,
        },
        tax_address: {
          value: null,
          n_addr_type_id: 1006,
          n_subj_address_id: null,
          n_subj_addr_type_id: 52325301,
          n_region_id: null,
        },
        invoice_shipping: {
          value: null,
          n_addr_type_id: 1006,
          n_subj_address_id: null,
          n_subj_addr_type_id: 52325401,
          n_region_id: null,
        },
      },
      isLoading: false,
      isIndividual: null,
    };
  },
  methods: {
    init: function() {
      this.getData();
    },
    validationForm() {
      this.$refs.general.validate().then((success) => {
        if (success) {
          if (confirm("Are you sure want to change this profile?")) {
            this.isLoading = true;
            this.add_log();
            this.update_touchpoint();
            axios
              .put("profile/update", {
                paramUser: this.paramUser, // data buat update person/organization di hydra
                paramAddress: this.paramAddress, // data buat update person/organizaton addresses
              })
              .then((response) => {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: "Load Dashboard",
                    icon: "EditIcon",
                    variant: "success",
                    text: response.data.message,
                  },
                });
              })
              .catch((error) => {})
              .finally(() => {
                this.isLoading = false;
                //this.$refs.general_form.reset();
                this.init();
              });
          }
        }
      });
    },
    getData() {
      this.isLoading = true;
      axios
        .get("profile/data")
        .then((response) => {
          this.isIndividual = response.data.isIndividual;

          this.paramUser.fullName = null;
          this.paramUser.birth = null;
          this.paramUser.birthPlace = null;
          this.paramAddress.email.value = null;
          this.paramAddress.email.n_subj_address_id = null;
          this.paramAddress.email.n_region_id = null;
          this.paramAddress.phone_number.value = null;
          this.paramAddress.phone_number.n_subj_address_id = null;
          this.paramAddress.phone_number.n_region_id = null;
          this.paramAddress.street_address.value = null;
          this.paramAddress.street_address.n_subj_address_id = null;
          this.paramAddress.street_address.n_region_id = null;
          this.paramAddress.tax_address.value = null;
          this.paramAddress.tax_address.n_subj_address_id = null;
          this.paramAddress.tax_address.n_region_id = null;
          this.paramAddress.invoice_shipping.value = null;
          this.paramAddress.invoice_shipping.n_subj_address_id = null;
          this.paramAddress.invoice_shipping.n_region_id = null;

          if (response.data.isIndividual == true) {
            // tampung param user individual
            this.paramUser.fullName = response.data.data.vc_first_name;
            this.paramUser.birth = response.data.data.d_birth;
            this.paramUser.birthPlace = response.data.data.vc_birth_place;

            // tampung param address individual
            if (response.data.address.email != null) {
              this.paramAddress.email.value = response.data.address.email.value;
              this.paramAddress.email.n_subj_address_id =
                response.data.address.email.n_subj_address_id;
              this.paramAddress.email.n_region_id =
                response.data.address.email.n_region_id;
            }
            if (response.data.address.phone_number != null) {
              this.paramAddress.phone_number.value =
                response.data.address.phone_number.value;
              this.paramAddress.phone_number.n_subj_address_id =
                response.data.address.phone_number.n_subj_address_id;
              this.paramAddress.phone_number.n_region_id =
                response.data.address.phone_number.n_region_id;
            }
            if (response.data.address.street_address != null) {
              this.paramAddress.street_address.value =
                response.data.address.street_address.value;
              this.paramAddress.street_address.n_subj_address_id =
                response.data.address.street_address.n_subj_address_id;
              this.paramAddress.street_address.n_region_id =
                response.data.address.street_address.n_region_id;
            }
            if (response.data.address.tax_address != null) {
              this.paramAddress.tax_address.value =
                response.data.address.tax_address.value;
              this.paramAddress.tax_address.n_subj_address_id =
                response.data.address.tax_address.n_subj_address_id;
              this.paramAddress.tax_address.n_region_id =
                response.data.address.tax_address.n_region_id;
            }
            if (response.data.address.invoice_shipping != null) {
              this.paramAddress.invoice_shipping.value =
                response.data.address.invoice_shipping.value;
              this.paramAddress.invoice_shipping.n_subj_address_id =
                response.data.address.invoice_shipping.n_subj_address_id;
              this.paramAddress.invoice_shipping.n_region_id =
                response.data.address.invoice_shipping.n_region_id;
            }
          } else {
            // tampung param user organization
            this.paramUser.fullName = response.data.data.vc_name;

            // tampung param address organization
            if (response.data.address.email != null) {
              this.paramAddress.email.value = response.data.address.email.value;
              this.paramAddress.email.n_subj_address_id =
                response.data.address.email.n_subj_address_id;
              this.paramAddress.email.n_region_id =
                response.data.address.email.n_region_id;
            }
            if (response.data.address.phone_number != null) {
              this.paramAddress.phone_number.value =
                response.data.address.phone_number.value;
              this.paramAddress.phone_number.n_subj_address_id =
                response.data.address.phone_number.n_subj_address_id;
              this.paramAddress.phone_number.n_region_id =
                response.data.address.phone_number.n_region_id;
            }
            if (response.data.address.street_address != null) {
              this.paramAddress.street_address.value =
                response.data.address.street_address.value;
              this.paramAddress.street_address.n_subj_address_id =
                response.data.address.street_address.n_subj_address_id;
              this.paramAddress.street_address.n_region_id =
                response.data.address.street_address.n_region_id;
            }
            if (response.data.address.tax_address != null) {
              this.paramAddress.tax_address.value =
                response.data.address.tax_address.value;
              this.paramAddress.tax_address.n_subj_address_id =
                response.data.address.tax_address.n_subj_address_id;
              this.paramAddress.tax_address.n_region_id =
                response.data.address.tax_address.n_region_id;
            }
            if (response.data.address.invoice_shipping != null) {
              this.paramAddress.invoice_shipping.value =
                response.data.address.invoice_shipping.value;
              this.paramAddress.invoice_shipping.n_subj_address_id =
                response.data.address.invoice_shipping.n_subj_address_id;
              this.paramAddress.invoice_shipping.n_region_id =
                response.data.address.invoice_shipping.n_region_id;
            }
          }

          // tampung untuk log
          this.paramLog.previous_data = JSON.stringify({
            nama: this.paramUser.fullName,
            birth: this.paramUser.birth,
            birth_place: this.paramUser.birthPlace,
            address: this.paramAddress.street_address.value,
            shipping_address: this.paramAddress.invoice_shipping.value,
            phone_number: this.paramAddress.phone_number.value,
          });
          // this.paramLog.fullNameBefore = this.paramUser.fullName;
          // this.paramLog.birthBefore = this.paramUser.birth;
          // this.paramLog.birthPlaceBefore = this.paramUser.birthplace;
          // this.paramLog.addressBefore = this.paramAddress.street_address.value;
          // this.paramLog.invoice_shippingBefore = this.paramAddress.invoice_shipping.value;
          // this.paramLog.phone_numberBefore = this.paramAddress.phone_number.value;

          //console.log(this.paramLog);
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Dashboard",
              icon: "EditIcon",
              variant: "success",
              text: response.data.message,
            },
          });
        })
        .catch((error) => {
          if (error.response.data.message == "Expired token") {
            localStorage.clear();
            this.$router.push({ name: "login" });
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Account",
                icon: "EditIcon",
                variant: "success",
                text: "Session Expired, Please Login Again",
              },
            });
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Account",
                icon: "EditIcon",
                variant: "success",
                text: error.response.data.message,
              },
            });
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
      //this.getdata();
    },
    resetForm() {
      //this.optionsLocal = JSON.parse(JSON.stringify(this.generalData))
      //this.$refs.general_form.reset();
      this.init();
    },
    onlyNumber($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
        // 46 is dot
        $event.preventDefault();
      }
    },
    add_log: function() {
      this.paramLog.new_data = JSON.stringify({
        nama: this.paramUser.fullName,
        birth: this.paramUser.birth,
        birth_place: this.paramUser.birthPlace,
        address: this.paramAddress.street_address.value,
        shipping_address: this.paramAddress.invoice_shipping.value,
        phone_number: this.paramAddress.phone_number.value,
      });
      axios.post("profile/log", this.paramLog).then((response) => {});
    },
    update_touchpoint: function() {
      axios
        .put("profile/update_touchpoint", {
          dob: this.paramUser.birth,
          address_instalasi: this.paramAddress.street_address.value,
          address_billing: this.paramAddress.invoice_shipping.value,
          phone: this.paramAddress.phone_number.value,
        })
        .then((response) => {});
    },
  },
  setup() {
    const refInputEl = ref(null);
    const previewEl = ref(null);

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, previewEl);

    return {
      refInputEl,
      previewEl,
      inputImageRenderer,
    };
  },
  mounted() {
    this.init();
  },
};
</script>
