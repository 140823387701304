<template>
  <div class="text-center" style="padding: 50px">
    <b-spinner variant="primary" label="Text Centered" />
    <b-card-text>{{ $t("loading") }}</b-card-text>
  </div>
</template>

<script>
import { BSpinner, BCardText, BCard } from "bootstrap-vue";

export default {
  components: {
    BCard,
    BSpinner,
    BCardText,
  },
  data() {
    return {};
  },
};
</script>
