<template>
  <b-card>
    <!-- form -->
    <validation-observer ref="chpass">
      <b-form ref="chpass_form" class="mt-2">
        <b-row>
          <!-- old password -->
          <b-col md="6">
            <b-form-group :label="$t('form.page.profile.Old Password')" label-for="account-old-password">
              <!--b-input-group class="input-group-merge"-->
                <validation-provider #default="{ errors }" name="old-password" rules="required">
                  <b-form-input id="account-old-password" 
                    v-model="passwordValueOld" 
                    name="old-password" 
                    type="password" 
                    :placeholder="$t('form.page.profile.Old Password')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
                <!--b-input-group-append is-text>
                  <feather-icon :icon="passwordToggleIconOld" class="cursor-pointer" @click="togglePasswordOld"/>
                </b-input-group-append-->
              <!--/b-input-group-->
            </b-form-group>
          </b-col>
          <!--/ old password -->
        </b-row>
        <b-row>
          <!-- new password -->
          <b-col md="6">
            <b-form-group :label="$t('form.page.profile.New Password')" label-for="account-new-password">
              <!--b-input-group class="input-group-merge"-->
                <validation-provider vid="password" #default="{ errors }" name="new-password" rules="required">
                  <b-form-input 
                    id="account-new-password" 
                    v-model="newPasswordValue"
                    name="new-password" 
                    type="password"
                    :placeholder="$t('form.page.profile.New Password')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
                <!--b-input-group-append is-text>
                  <feather-icon :icon="passwordToggleIconNew" class="cursor-pointer" @click="togglePasswordNew"/>
                </b-input-group-append-->
              <!--/b-input-group-->
            </b-form-group>
          </b-col>
          <!--/ new password -->

          <!-- retype password -->
          <b-col md="6">
            <b-form-group :label="$t('form.page.profile.Retype New Password')" label-for="account-retype-new-password">
              <!--b-input-group class="input-group-merge"-->
                <validation-provider #default="{ errors }" name="retype-password" rules="required|confirmed:password">
                  <b-form-input 
                    id="account-retype-new-password" 
                    v-model="RetypePassword"
                    name="retype-password" 
                    type="password"
                    :placeholder="$t('form.page.profile.Retype New Password')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
                <!--b-input-group-append is-text>
                  <feather-icon :icon="passwordToggleIconRetype" class="cursor-pointer" @click="togglePasswordRetype"/>
                </b-input-group-append-->
              <!--/b-input-group-->
            </b-form-group>
          </b-col>
          <!--/ retype password -->

          <!-- buttons -->
          <b-col cols="12">
            <b-button @click.prevent="validationForm" v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mt-1 mr-1"> Save changes</b-button>
            <b-button @click="resetForm" v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="reset" variant="outline-secondary" class="mt-1"> Reset</b-button>
          </b-col>
          <!--/ buttons -->
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import {
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BCard,
  BInputGroup,
  BInputGroupAppend,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import axios from "@axios";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { required, email } from "@validations";

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BInputGroup,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      passwordValueOld: "",
      newPasswordValue: "",
      RetypePassword: "",
      passwordFieldTypeOld: "password",
      passwordFieldTypeNew: "password",
      passwordFieldTypeRetype: "password",
    };
  },
  computed: {
    passwordToggleIconOld() {
      return this.passwordFieldTypeOld === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    passwordToggleIconNew() {
      return this.passwordFieldTypeNew === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    passwordToggleIconRetype() {
      return this.passwordFieldTypeRetype === "password" ? "EyeIcon" : "EyeOffIcon";
    },
  },
  methods: {
    togglePasswordOld() {
      this.passwordFieldTypeOld =
        this.passwordFieldTypeOld === "password" ? "text" : "password";
    },
    togglePasswordNew() {
      this.passwordFieldTypeNew =
        this.passwordFieldTypeNew === "password" ? "text" : "password";
    },
    togglePasswordRetype() {
      this.passwordFieldTypeRetype =
        this.passwordFieldTypeRetype === "password" ? "text" : "password";
    },
    validationForm() {
      this.$refs.chpass.validate().then((success) => {
        if (success) {
           if (confirm("Are you sure want to change Password?")) {
            this.isLoading = true;
            axios.put("profile/change_password", {
              oldpassword: this.passwordValueOld, // data buat update person/organization di hydra
              newpassword: this.newPasswordValue, // data buat update person/organizaton addresses
            }).then((response) => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Change Password",
                  icon: "EditIcon",
                  variant: "success",
                  text: response.data.message,
                },
              });
            }).catch((error) => {

            }).finally(() => {
              this.isLoading = false;
              this.$refs.chpass_form.reset();
              this.passwordValueOld = null;
              this.newPasswordValue = null;
              this.RetypePassword = null;
            });
          }
        }
      });
    },
    resetForm(){
      this.$refs.chpass_form.reset();
      this.passwordValueOld = null;
      this.newPasswordValue = null;
      this.RetypePassword = null;
    }
  },
};
</script>
