var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('validation-observer',{ref:"general"},[_c('b-form',{ref:"general_form",staticClass:"mt-2"},[(!_vm.isLoading)?_c('b-row',[_c('b-col',{attrs:{"sm":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('form.page.profile.Fullname'),"label-for":"account-fullname"}},[_c('validation-provider',{attrs:{"name":"Fullname","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"disabled":"","placeholder":_vm.$t('form.page.profile.Fullname'),"name":"fullname","state":errors.length > 0 ? false : null},model:{value:(_vm.paramUser.fullName),callback:function ($$v) {_vm.$set(_vm.paramUser, "fullName", $$v)},expression:"paramUser.fullName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3182234513)})],1)],1),_c('b-col',{attrs:{"sm":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('form.page.profile.Email'),"label-for":"account-email"}},[_c('validation-provider',{attrs:{"name":"Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"disabled":"","placeholder":_vm.$t('form.page.profile.Email'),"name":"email","state":errors.length > 0 ? false : null},model:{value:(_vm.paramAddress.email.value),callback:function ($$v) {_vm.$set(_vm.paramAddress.email, "value", $$v)},expression:"paramAddress.email.value"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3581679083)})],1)],1),(_vm.isIndividual)?_c('b-col',{attrs:{"sm":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('form.page.profile.Birth Date'),"label-for":"account-birth"}},[_c('validation-provider',{attrs:{"name":"Birth","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('flat-pickr',{staticClass:"form-control",attrs:{"disabled":"","readonly":"","name":"date","placeholder":_vm.$t('form.page.profile.Birth Date')},model:{value:(_vm.paramUser.birth),callback:function ($$v) {_vm.$set(_vm.paramUser, "birth", $$v)},expression:"paramUser.birth"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,4155733103)})],1)],1):_vm._e(),(_vm.isIndividual)?_c('b-col',{attrs:{"sm":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('form.page.profile.Birth Place'),"label-for":"account-birthplace"}},[_c('validation-provider',{attrs:{"name":"Birthplace","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"disabled":"","placeholder":_vm.$t('form.page.profile.Birth Place'),"name":"birthplace","state":errors.length > 0 ? false : null},model:{value:(_vm.paramUser.birthPlace),callback:function ($$v) {_vm.$set(_vm.paramUser, "birthPlace", $$v)},expression:"paramUser.birthPlace"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,963666427)})],1)],1):_vm._e(),_c('b-col',{attrs:{"sm":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('form.page.profile.Address'),"label-for":"bio-address"}},[_c('validation-provider',{attrs:{"name":"Address","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"disabled":"","id":"bio-address","rows":"4","placeholder":_vm.$t('form.page.profile.Address')},model:{value:(_vm.paramAddress.street_address.value),callback:function ($$v) {_vm.$set(_vm.paramAddress.street_address, "value", $$v)},expression:"paramAddress.street_address.value"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2691420126)})],1)],1),_c('b-col',{attrs:{"sm":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('form.page.profile.Tax Address'),"label-for":"bio-taxaddress"}},[_c('validation-provider',{attrs:{"name":"Tax Address","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"disabled":"","id":"bio-taxaddress","rows":"4","placeholder":_vm.$t('form.page.profile.Tax Address')},model:{value:(_vm.paramAddress.tax_address.value),callback:function ($$v) {_vm.$set(_vm.paramAddress.tax_address, "value", $$v)},expression:"paramAddress.tax_address.value"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3348313650)})],1)],1),_c('b-col',{attrs:{"sm":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('form.page.profile.invoice shipping'),"label-for":"bio-taxaddress"}},[_c('validation-provider',{attrs:{"name":"Invoice Shipping Address","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"disabled":"","id":"bio-taxaddress","rows":"4","placeholder":_vm.$t('form.page.profile.invoice shipping')},model:{value:(_vm.paramAddress.invoice_shipping.value),callback:function ($$v) {_vm.$set(_vm.paramAddress.invoice_shipping, "value", $$v)},expression:"paramAddress.invoice_shipping.value"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2562408850)})],1)],1),_c('b-col',{attrs:{"sm":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('form.page.profile.Phone Number'),"label-for":"account-phone"}},[_c('validation-provider',{attrs:{"name":"Phone","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"disabled":"","placeholder":_vm.$t('form.page.profile.Phone Number'),"name":"phone","state":errors.length > 0 ? false : null},model:{value:(_vm.paramAddress.phone_number.value),callback:function ($$v) {_vm.$set(_vm.paramAddress.phone_number, "value", $$v)},expression:"paramAddress.phone_number.value"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1908007172)})],1)],1)],1):_vm._e(),(_vm.isLoading)?[_c('loading')]:_vm._e()],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }