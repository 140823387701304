
<template>
  <div style="color: #000">
    <template>
      <div class="d-flex flex-grow-1 align-items-center mr-1">
        <b-img
          :src="require('@/assets/images/logo/logo.png')"
          class="mr-1"
          height="25"
          width="25"
          alt="Toast image"
        />
        <span class="mr-auto">{{ title }}</span>
        <feather-icon
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"

          v-if="!hideClose"
          icon="XIcon"
          class="cursor-pointer toastification-close-icon ml-auto text-body"
          @click="$emit('close-toast')"
        />
      </div>
    </template>
    <span > {{ text }} </span>
    <!-- <span >{{text}}</span
    > -->
  </div>
</template>

<script>
import { BButton, BToast, BImg } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    BButton,
    BToast,
    BImg,
  },
  directives: {
    Ripple,
  },
  props: {
    variant: {
      type: String,
      default: "primary",
    },
    icon: {
      type: String,
      default: null,
    },
    title: {
      type: String,
      default: null,
    },
    text: {
      type: String,
      default: null,
    },
    hideClose: {
      type: Boolean,
      default: false,
    },
  },
};
</script>